import Tealium from "@4tn/webx-analytics";
import { noticeError } from "./newRelic";

const isClient = typeof window !== "undefined";

const maxCacheTime = 120000; // 120sec

export enum FeatureSlugs {
  FIRST_FEATURE = "first-feature",
  ADOBE_DELAY = "adobe-delay",
  ADBLOCK_DETECTION = "adblock-detection",
  DISPLAY_ADS = "display-ads-sdk",
  SUBSCRIPTION_TEXT = "subscription-legal-text",
  TABLE_FILTER = "table-filter",
  TALPA_AUTH = "talpa-auth",
}

export class FeatureTooling {
  debug = isClient && document.cookie.includes("__FEATURE_DEBUG__");
  features: IFeature[] = [];
  featuresTimestamp: number = 0;
  dataFileUrl = "";
  activeFeatures: IFeature[] = [];
  featuresCache: { [id: string]: boolean } = {};
  attributes: Record<string, string | undefined> = {};

  constructor() {
    if (isClient) {
      // hydration
      try {
        const element = document.getElementById("__FEATURES__");
        if (!element) return;
        const { features, attributes } = JSON.parse(element.innerHTML);
        this.features = features;
        this.attributes = attributes;
        this.updateActiveFeatures();
      } catch (error) {
        this.features = [];
        noticeError(error as Error, { customMessage: "Feature hydration failed" });
      }
    }
  }

  fetchFeatures = async (dataFileUrl: string) => {
    try {
      const currentTime = Date.now();

      // feature file shouldn't be cached longer than 120sec
      if (
        this.features?.length &&
        currentTime - this.featuresTimestamp < maxCacheTime &&
        dataFileUrl === this.dataFileUrl
      ) {
        return this.features;
      }
      this.features = await (await fetch(dataFileUrl)).json();
      this.featuresTimestamp = currentTime;
      this.dataFileUrl = dataFileUrl;
      this.reset();
      return this.features;
    } catch (error) {
      noticeError(error as Error, { customMessage: "There was an error while fetching the feature flags." });
    }
    return [];
  };

  private updateActiveFeatures = () => {
    this.activeFeatures = this.features.filter(({ enabled }) => enabled);
    if (isClient) {
      window.eventBus.dispatch("featureReset", this.activeFeatures);
      Tealium.setDataLayer({
        experiment_features: this.activeFeatures.map(({ slug }) => slug).join("|"),
      });
    }
  };

  private log = (msg: string): void => {
    if (!this.debug) return;
    // eslint-disable-next-line no-console
    console.log("%c [FeatureTooling]", "font-weight:bold", msg);
  };

  isEnabled = (featureSlug: string): boolean => {
    if (!this.features.length) {
      return false;
    }
    if (this.featuresCache[featureSlug] === undefined) {
      const enabled = this.activeFeatures.some((activeFeature) => activeFeature.slug === featureSlug);

      this.featuresCache[featureSlug] = enabled;
      const feature = this.features.find(({ slug }) => slug === featureSlug);

      this.log(
        feature
          ? `feature ${featureSlug} is ${enabled ? "" : "not "}enabled`
          : `feature ${featureSlug} not found in data file`
      );
    }
    return this.featuresCache[featureSlug];
  };

  getVariable = <T>(featureSlug: string, variableKey: string, defaultValue: any = null): T | null => {
    const variables = this.getVariables<{ [key: string]: unknown }>(featureSlug);

    if (variables) {
      return typeof variables[variableKey] !== "undefined" ? variables[variableKey] : defaultValue;
    }
    return defaultValue;
  };

  getVariables = <T>(featureSlug: string): T | null => {
    if (!this.features.length || !this.isEnabled(featureSlug)) {
      return null;
    }

    const { variables } = this.features.find(({ slug }) => slug === featureSlug) || {};
    return variables;
  };

  reset = (): void => {
    this.featuresCache = {};
    this.updateActiveFeatures();
  };
}

const featureTooling = new FeatureTooling();

if (isClient) {
  (window as any).__featureTooling = featureTooling;
}

export { default as useFeature } from "./useFeature";

export default featureTooling;
